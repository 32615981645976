import React, { createContext, useCallback, useEffect, useState } from "react";
import { useAuth, useGraphql } from "hooks";
import {
  enrolPremierClubMutation,
  confirmPremierClubAttendanceMutation,
} from "api/mutations";
import { getPremierClubSummaryQuery } from "api/queries";

export const PremierClubContext = createContext({
  campaignSummary: {},
  enrol: () => {},
  isLoading: true,
  updateCampaignSummary: () => {},
  confirmAttendance: () => {},
});

export const PremierClubProvider = ({ children }) => {
  const { user } = useAuth();

  const [campaignSummary, setCampaignSummary] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { executeQuery: executeEnrolPremierClubMutation } = useGraphql(
    enrolPremierClubMutation
  );

  const { executeQuery: executeGetPremierClubSummaryQuery } = useGraphql(
    getPremierClubSummaryQuery
  );

  const { executeQuery: executeConfirmPremierClubAttendanceMutation } =
    useGraphql(confirmPremierClubAttendanceMutation);

  const enrol = useCallback(async () => {
    return executeEnrolPremierClubMutation();
  }, [executeEnrolPremierClubMutation]);

  const confirmAttendance = useCallback(
    async (attending) => {
      return executeConfirmPremierClubAttendanceMutation({
        attending: attending,
      });
    },
    [executeConfirmPremierClubAttendanceMutation]
  );

  const updateCampaignSummary = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await executeGetPremierClubSummaryQuery();
      const data = response.data.getPremierClubSummary;
      setCampaignSummary(data);
      setIsLoading(false);
    } catch {
      setCampaignSummary({});
      setIsLoading(false);
    }
  }, [executeGetPremierClubSummaryQuery]);

  useEffect(() => {
    if (user.isApproved) {
      updateCampaignSummary();
    }
  }, [user, updateCampaignSummary]);

  return (
    <PremierClubContext.Provider
      value={{
        campaignSummary,
        enrol,
        isLoading,
        updateCampaignSummary,
        confirmAttendance,
      }}
    >
      {children}
    </PremierClubContext.Provider>
  );
};
